import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Icon icons=Add wrap=flex",
        "componentName": "Icon",
        "icons": "Add",
        "wrap": "flex"
      }}>{`<Icon as={Add} color="green50" />
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Icon" mdxType="Props" />
    <h2 {...{
      "id": "as",
      "style": {
        "position": "relative"
      }
    }}>{`As`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Provide an svgr icon to the Icon component, please choose one of our pre-defined
iconset.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Icon icons=Add,Brand,Shield wrap=flex",
        "componentName": "Icon",
        "icons": "Add,Brand,Shield",
        "wrap": "flex"
      }}>{`<Icon as={Add} />
<Icon as={Brand} />
<Icon as={Shield} />
`}</code></pre>
    <h2 {...{
      "id": "as-1",
      "style": {
        "position": "relative"
      }
    }}>{`As`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Provide the icon component you want to use.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Icon icons=Add,Brand,Shield wrap=flex",
        "componentName": "Icon",
        "icons": "Add,Brand,Shield",
        "wrap": "flex"
      }}>{`<Icon as={Add} />
<Icon as={Brand} />
<Icon as={Shield} />
`}</code></pre>
    <h2 {...{
      "id": "size",
      "style": {
        "position": "relative"
      }
    }}>{`Size`}</h2>
    <p><inlineCode parentName="p">{`default: medium`}</inlineCode></p>
    <p>{`All icons are available in sets of four different sizes, where the `}<strong parentName="p">{`default`}</strong>{`
size is `}<strong parentName="p">{`Medium`}</strong>{`.`}</p>
    <ul>
      <li parentName="ul">{`Small`}</li>
      <li parentName="ul">{`Medium`}</li>
      <li parentName="ul">{`Large`}</li>
      <li parentName="ul">{`xLarge`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Icon icons=Brand wrap=flex",
        "componentName": "Icon",
        "icons": "Brand",
        "wrap": "flex"
      }}>{`<Icon as={Brand} size="small" />
<Icon as={Brand} size="medium" />
<Icon as={Brand} size="large" />
<Icon as={Brand} size="xLarge" />
`}</code></pre>
    <h2 {...{
      "id": "i-just-need-svg-files-not-react-components",
      "style": {
        "position": "relative"
      }
    }}>{`I just need `}<inlineCode parentName="h2">{`SVG`}</inlineCode>{` files, not React components.`}</h2>
    <p>{`Good for you! We also supply these icons as `}<inlineCode parentName="p">{`SVG`}</inlineCode>{` files, you can find them in
each theme package under `}<inlineCode parentName="p">{`lib/web/icons`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "looking-for-icons",
      "style": {
        "position": "relative"
      }
    }}>{`Looking for icons?`}</h2>
    <ul>
      <li parentName="ul">{`Designers can browse and discover icons in our
`}<a parentName="li" {...{
          "href": "https://www.figma.com/file/uQuolYS7f1FqHH4UxSY88k/2.-Foundations-Icons?node-id=30%3A2"
        }}>{`Figma Design Toolkit`}</a>{`.`}</li>
      <li parentName="ul">{`Developers can find icons in our
`}<a parentName="li" {...{
          "href": "/components/icon/icons"
        }}>{`Chameleon icon search`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      